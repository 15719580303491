
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IdeProject"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Project"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userPermissions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMergeRequestIn"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"readMergeRequest"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pushCode"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":125}};
    doc.loc.source = {"body":"fragment IdeProject on Project {\r\n  userPermissions {\r\n    createMergeRequestIn\r\n    readMergeRequest\r\n    pushCode\r\n  }\r\n}\r\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
