import getIdeProject from 'ee_else_ce/ide/queries/get_ide_project.query.graphql';
import Api from '~/api';
import dismissUserCallout from '~/graphql_shared/mutations/dismiss_user_callout.mutation.graphql';
import axios from '~/lib/utils/axios_utils';
import { joinPaths, escapeFileUrl } from '~/lib/utils/url_utility';
import ciConfig from '~/pipeline_editor/graphql/queries/ci_config.graphql';
import { query, mutate } from './gql';


const fetchApiProjectData = (projectPath) => Api.project(projectPath).then(({ data }) => data);

const fetchGqlProjectData = (projectPath) =>
  query({
    query: getIdeProject,
    variables: { projectPath },
  }).then(({ data }) => data.project);
// -----------cq 请求获取编辑文件内容-----------------//
export default {
  getFileData (endpoint) {

    // const store = getters.currentProject(183);
    // // const porjectID = store.getters.currentProject;
    // console.log("store", store);
    const path = endpoint.replace(/\//g, "%2F");

    const url = `https://hub.thinkgs.cn/api/v4/projects/${window.tempProID}/repository/files/${path}?ref=${window.branchid}`;

    return axios.get(url, {
      headers: {
        'PRIVATE-TOKEN': window.PRIVATE_TOKEN, // 设置header信息
      },
    });
  },
  getRawFileData (file) {

    if (file.tempFile && !file.prevPath) {
      return Promise.resolve(file.content);
    }
    const fileId = file.id;
    file.rawPath = `https://hub.thinkgs.cn/api/v4/projects/${window.tempProID}/repository/blobs/${fileId}/raw`;
    if (file.raw || !file.rawPath) {
      return Promise.resolve(file.raw);
    }

    // const options = file.binary ? { responseType: 'arraybuffer' } : {};

    return axios.get(file.rawPath, {
      headers: {
        'PRIVATE-TOKEN': window.PRIVATE_TOKEN, // 设置header信息
      },
    }).then(({ data }) => {
      if (typeof data === 'object') {
        return JSON.stringify(data, null, 2)
      }
      return data;
    });
  },
  getBaseRawFileData (file, projectId, ref) {
    if (file.tempFile || file.baseRaw) return Promise.resolve(file.baseRaw);
    console.log(projectId,"projectId56562")
    // if files are renamed, their base path has changed
    const filePath =
      file.mrChange && file.mrChange.renamed_file ? file.mrChange.old_path : file.path;

    return axios
      .get(
        joinPaths(
          gon.relative_url_root || '/',
          projectId,
          '-',
          'raw',
          ref,
          escapeFileUrl(filePath),
        ),
        {
          transformResponse: [(f) => f],
        },
      )
      .then(({ data }) => data);
  },
  // ------cq 第一个接口 拉取项目信息--------------//
  getProjectData (namespace, project) {
    const projectPath = `${namespace}/${project}`;

    return Promise.all([fetchApiProjectData(projectPath), fetchGqlProjectData(projectPath)]).then(
      ([apiProjectData, gqlProjectData]) => ({
        data: {
          ...apiProjectData,
          ...gqlProjectData,
        },
      }),
    );
  },
  getProjectMergeRequests (projectId, params = {}) {
    return Api.projectMergeRequests(projectId, params);
  },
  getProjectMergeRequestData (projectId, mergeRequestId, params = {}) {
    return Api.projectMergeRequest(projectId, mergeRequestId, params);
  },
  getProjectMergeRequestChanges (projectId, mergeRequestId) {
    return Api.projectMergeRequestChanges(projectId, mergeRequestId);
  },
  getProjectMergeRequestVersions (projectId, mergeRequestId) {
    return Api.projectMergeRequestVersions(projectId, mergeRequestId);
  },
  getBranchData (projectId, currentBranchId) {
    return Api.branchSingle(projectId, currentBranchId);
  },
  commit (projectId, payload) {
    console.log("commit1234");
    // console.log(projectId,"projectId")
    return Api.commitMultiple(projectId, payload);
  },
  submitForm(projectId, payload){
    console.log("submitForm");
    console.log(projectId,"projectId")
    return Api.commitMultiple(projectId, payload);
  },
  // ----cq-----获取文件树------------//
  getFiles (projectPath, ref) {
    const pannel = `src/${window.pannelId}`;
    return axios({
      method: 'get',
      url: `https://hub.thinkgs.cn/api/v4/projects/${window.tempProID}/repository/tree?ref=${window.branchid}&recursive=true&path=${pannel}`,
      headers: {
        'PRIVATE-TOKEN': window.PRIVATE_TOKEN,
      } 
    })
  },
  lastCommitPipelines ({ getters }) {
    const commitSha = getters.lastCommit.id;
    return Api.commitPipelines(getters.currentProject.path_with_namespace, commitSha);
  },
  pingUsage (projectPath) {
    // const url = `${gon.relative_url_root}/${projectPath}/usage_ping/web_ide_pipelines_count`;
    const url = `https://hub.thinkgs.cn/${projectPath}/usage_ping/web_ide_pipelines_count`;
    return axios.post(url);
  },
  getCiConfig (projectPath, content) {
    return query({
      query: ciConfig,
      variables: { projectPath, content },
    }).then(({ data }) => data.ciConfig);
  },
  dismissUserCallout (name) {
    return mutate({
      mutation: dismissUserCallout,
      variables: { input: { featureName: name } },
    }).then(({ data }) => data);
  },
};
