import { defer } from 'lodash';
import { createStore } from '~/ide/stores';
import {
  WEBIDE_MARK_FETCH_FILES_FINISH,
  WEBIDE_MEASURE_FETCH_FILES,
  WEBIDE_MARK_FETCH_FILES_START,
} from '~/performance/constants';
import { performanceMarkAndMeasure } from '~/performance/utils';
import { __ } from '../../../locale';
import { decorateFiles } from '../../lib/files';
import service from '../../services';
import * as types from '../mutation_types';

let store;

export const toggleTreeOpen = ({ commit }, path) => {
  commit(types.TOGGLE_TREE_OPEN, path);
};

export const showTreeEntry = ({ commit, dispatch, state }, path) => {
  const entry = state.entries[path];
  const parentPath = entry ? entry.parentPath : '';

  if (parentPath) {
    commit(types.SET_TREE_OPEN, parentPath);

    dispatch('showTreeEntry', parentPath);
  }
};

export const handleTreeEntryAction = ({ commit, dispatch }, row) => {
  if (row.type === 'tree') {
    dispatch('toggleTreeOpen', row.path);
  } else if (row.type === 'blob') {
    if (!row.opened) {
      commit(types.TOGGLE_FILE_OPEN, row.path);
    }

    dispatch('setFileActive', row.path);
  }

  dispatch('showTreeEntry', row.path);
};

export const setDirectoryData = ({ state, commit }, { projectId, branchId, treeList }) => {
  const selectedTree = state.trees[`${projectId}/${branchId}`];

  commit(types.SET_DIRECTORY_DATA, {
    treePath: `${projectId}/${branchId}`,
    data: treeList,
  });
  commit(types.TOGGLE_LOADING, {
    entry: selectedTree,
    forceValue: false,
  });
};

export const getFiles = ({ state, commit, dispatch }, payload = {}) => {
  performanceMarkAndMeasure({ mark: WEBIDE_MARK_FETCH_FILES_START });
  return new Promise((resolve, reject) => {
    const { projectId, branchId, ref = branchId } = payload;

    if (
      !state.trees[`${projectId}/${branchId}`] ||
      (state.trees[`${projectId}/${branchId}`].tree &&
        state.trees[`${projectId}/${branchId}`].tree.length === 0)
    ) {
      const selectedProject = state.projects[projectId];

      commit(types.CREATE_TREE, { treePath: `${projectId}/${branchId}` });
      service
        .getFiles(selectedProject.path_with_namespace, ref)
        .then(({ data }) => {
          performanceMarkAndMeasure({
            mark: WEBIDE_MARK_FETCH_FILES_FINISH,
            measures: [
              {
                name: WEBIDE_MEASURE_FETCH_FILES,
                start: WEBIDE_MARK_FETCH_FILES_START,
              },
            ],
          });
          // -----cq-- 构建菜单树数组--------------//
          const newTree = [];
          let isnew;
          if (data.length) {
            data.forEach(item => {
              newTree.push(item.path)
            })
            newTree.push("package.json");
            data = newTree;
            isnew = false;
          } else {

            newTree.push(`src/${window.pannelId}/App.vue`)
            newTree.push(`src/${window.pannelId}/main.js`)
            newTree.push(`src/${window.pannelId}/page.html`)
            data = newTree;
            isnew = true;
            store = createStore();
            store.state.stagedFiles = [`src/${window.pannelId}/App.vue`, `src/${window.pannelId}/main.js`];
          }

          const { entries, treeList } = isnew ? decorateFiles({ data, tempFile: true, content: "" }) : decorateFiles({ data });
          if (isnew) {

            entries[`src/${window.pannelId}/main.js`].content = `${__("import Vue from 'vue';")}\n` +
              `import App from './App.vue';` + `\n` +
              `import ElementUI from 'element-ui';` + `\n` +

              `Vue.use(ElementUI);` + `\n` +

              `Vue.config.productionTip = false;` + `\n${__("new Vue({")}\n${__("render: h => h(App)")}\n${__("  }).$mount('#")}${window.pannelId
              }'); `
            entries[`src/${window.pannelId}/App.vue`].content = `${`${"<template>" + '\n' +
              "<div id='app'>" + '\n' +
              "</div>" + '\n' +
              "</template>" + '\n' +
              "<script>" + '\n'}${__("export default {")}\n${__("  name: 'App',")}\n${__("  components: {")}\n` +
              ` },` + `\n${__("data() {")}\n${__(" return {")}\n` +
              ` }` + `\n` +
              ` },` + `\n${__(" methods: {")}\n` +
              ` }` + `\n` +
              ` }` + `\n` +
              `</script>` + `\n` +
              `<style>` + `\n${__("#app {")}\n${__("font-family: Avenir, Helvetica, Arial, sans-serif;")}\n${__("-webkit-font-smoothing: antialiased;")}\n${__(" -moz-osx-font-smoothing: grayscale;")}\n${__(" text-align: center;")}\n${__(" color: #2c3e50;")}\n${__("  margin-top: 60px;")}\n` +
              ` }` + `\n` +
              `</style>`}`;


              entries[`src/${window.pannelId}/page.html`].content = `${"<!DOCTYPE html>" + '\n' +
              "<html lang=''>" + '\n' +
              "<body>" + '\n' +
              "<div id='app'>" + '\n' +
              "</div>" + '\n' +
              "</body>" + '\n' +
              "</html>" + '\n' 
                }`;
          }
          console.log(entries);
          commit(types.SET_ENTRIES, entries);

          // Defer setting the directory data because this triggers some intense rendering.
          // The entries is all we need to load the file editor.
          defer(() => dispatch('setDirectoryData', { projectId, branchId, treeList }));

          resolve();
        })
        .catch((e) => {
          dispatch('setErrorMessage', {
            text: __('An error occurred while loading all the files.'),
            action: (actionPayload) =>
              dispatch('getFiles', actionPayload).then(() => dispatch('setErrorMessage', null)),
            actionText: __('Please try again'),
            actionPayload: { projectId, branchId },
          });
          reject(e);
        });
    } else {
      resolve();
    }
  });
};

export const restoreTree = ({ dispatch, commit, state }, path) => {
  const entry = state.entries[path];

  commit(types.RESTORE_TREE, path);

  if (entry.parentPath) {
    dispatch('restoreTree', entry.parentPath);
  }
};
