<script>
import { GlModal, GlButton } from '@gitlab/ui';
import { mapActions, mapState, mapGetters } from 'vuex';
import createFlash from '~/flash';
import { __, sprintf, s__ } from '~/locale';
import { modalTypes,leftSidebarViews,packageJsonPath } from '../../constants';
import { trimPathComponents, getPathParent } from '../../utils';
// import { , MAX_WINDOW_HEIGHT_COMPACT } from '../../constants';
// import {getFileData} from '../../stores/modules/actions/file'
import {
  COMMIT_TO_CURRENT_BRANCH,
  COMMIT_TO_NEW_BRANCH,
} from '../../stores/modules/commit/constants';
import axios from '../../../lib/utils/axios_utils';
import { joinPaths } from '../../../lib/utils/url_utility';
// import Api  from '../../../api'
const Api={
  commitsPath: '/api/:version/projects/:id/repository/commits',
    applySuggestionPath: '/api/:version/suggestions/:id/apply',
  applySuggestionBatchPath: '/api/:version/suggestions/batch_apply',
  groupPath: '/api/:version/groups/:id',
  buildUrl (url) {
    return joinPaths(gon.relative_url_root || 'https://hub.thinkgs.cn', url.replace(':version', gon.api_version));
  },
  group (groupId, callback = () => { }) {
    const url = Api.buildUrl(Api.groupPath).replace(':id', groupId);
    return axios.get(url).then(({ data }) => {
      callback(data);
      console.log(data,"data")
    // localStorage.setItem("data",JSON.stringify(data))
    // localStorage.setItem("groupId",JSON.stringify(groupId))
      return data;
    });
  },
 
  // getrunnning (gitProID) {
  //   const myHeaders = new Headers();
  //   myHeaders.append('PRIVATE-TOKEN', window.PRIVATE_TOKEN);
  //   const path = `https://hub.thinkgs.cn/api/v4/projects/${window.tempProID}/jobs?scope[]=running&scope[]=pending`;
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow',
  //   };

  //   return fetch(
  //     path,
  //     requestOptions
  //   );
  // },
  fetchGit () {
    console.log(98752)
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    return fetch(
      `http://${window.runip}:${window.runPort}/fetch-git-src`,
      requestOptions
    );
  },
   async commitMultiple (id,data) {
    // var data= JSON.parse(localStorage.getItem("data"))
    // var id= TEST_PROJECT_ID
    // console.log("data", data);
    // console.log("id", id);
    // see https://docs.gitlab.com/ee/api/commits.html#create-a-commit-with-multiple-files-and-actions
    // const buliding = await this.getrunnning().then((response) => response.text());
    // console.log(buliding,"buliding")
    // if (buliding !== '[]') {
    //   data.commit_message += '[skip ci]';
    // }
    const url = 'https://hub.thinkgs.cn/api/v4/projects/'+encodeURIComponent(id)+'/repository/commits';
    // const url = buliding;
    // var url = 'http://xx'+id+"/";
    // console.log(url,"url")
    const res = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'PRIVATE-TOKEN': localStorage.getItem('PRIVATE-TOKEN')
      },
    });
    console.log(res,"res")
    if (window.runip && window.runPort) {
      console.log(852)
      Api.fetchGit();
    }
    //  this.getFileData({
    //     path: this.file.path,
    //   }).then(() =>
    //     this.getRawFileData({
    //       path: this.file.path,
    //     }),
    //   );
    // this.refreshLastCommitData(id,localStorage.getItem('branchid'))
    return res;
  },
  //   applySuggestion (id, message = '') {
  //   const url = Api.buildUrl(Api.applySuggestionPath).replace(':id', encodeURIComponent(id));
  //   const params = { commit_message: message };

  //   return axios.put(url, params);
  // },

  // applySuggestionBatch (ids) {
  //   const url = Api.buildUrl(Api.applySuggestionBatchPath);

  //   return axios.put(url, { ids });
  // },
}

export default {
  components: {
    GlModal,
    GlButton,
    Api,

  },
  mounted(){

  },
  data () {
    return {
      entryName: '',
      modalType: modalTypes.blob,
      path: '',
    };
  },
  computed: {
    ...mapState(['entries']),
    ...mapGetters('fileTemplates', ['templateTypes']),
    ...mapGetters(['canPushCodeStatus']),
    // ...mapGetters(['someUncommittedChanges', 'canPushCodeStatus']),
    modalTitle () {
      const entry = this.entries[this.path];

      if (this.modalType === modalTypes.tree) {
        return __('Create new directory');
      } else if (this.modalType === modalTypes.rename) {
        return entry.type === modalTypes.tree ? __('Rename folder') : __('Rename file');
      }

      return __('Create new file');
    },
    buttonLabel () {
      
      // this.commitMultiple()
      const entry = this.entries[this.path];
      if (this.modalType === modalTypes.tree) {
        return __('Create directory');
      } else if (this.modalType === modalTypes.rename) {
        return entry.type === modalTypes.tree ? __('Rename folder') : __('Rename file');
      }
      // console.log(123456789)
      // this.commitMultiple()
      return __('Create file');
    },
    isCreatingNewFile () {
      return this.modalType === modalTypes.blob;
    },
    placeholder () {
      return this.isCreatingNewFile ? 'dir/file_name' : 'dir/';
    },
  },
  methods: {
    ...mapActions(['createTempEntry', 'renameEntry','refreshLastCommitData','updateActivityBarView','getFileData',
      'getRawFileData',]),
    ...mapActions('commit',[
      'updateCommitMessage',
      'discardDraft',
      'commitChanges',
      'updateCommitAction',
      'updateBranchName'
    ]),
         // --------------------cq 代码提交----------------------------//
   async submitForm () {
      this.entryName = trimPathComponents(this.entryName);
      if (this.modalType === modalTypes.rename) {
        console.log(123)
        if (this.entries[this.entryName] && !this.entries[this.entryName].deleted) {
          createFlash({
            message: sprintf(s__('The name "%{name}" is already taken in this directory.'), {
              name: this.entryName,
            }),
            type: 'alert',
            parent: document,
            actionConfig: null,
            fadeTransition: false,
            addBodyClass: true,
          });
          // console.log(123)
        } else {
          let parentPath = this.entryName.split('/');
          const name = parentPath.pop();
          parentPath = parentPath.join('/');
          this.renameEntry({
            path: this.path,
            name,
            parentPath,
          });
          // console.log(456)
        }
        
      } else {
        this.createTempEntry({
          name: this.entryName,
          type: this.modalType,
        });
        var id = localStorage.getItem('gitUserName')+"/webpack4-cli";
        var data = {
          actions :[
            { action: "create",
            content: "\n",
          encoding: "text",
          file_path: this.entryName,
          last_commit_id: "",}
          ],
          branch:localStorage.getItem('branchid'),
          commit_message:"create  "+this.entryName+" file [skip ci]"
        }
        // return this.updateActivityBarView(leftSidebarViews.commit.name).then(() => {
          
        // this.isCompact = false;
        // });
        var commitErrorModal=localStorage.getItem("commitErrorModal")
        console.log(commitErrorModal,"commitErrorModal")
        if (window.branchid === 'master') {
     await  this.updateCommitAction(COMMIT_TO_NEW_BRANCH);
        const branch = `dashboard-${window.pannelId.split("-")[1]}`;
      await  this.updateBranchName(branch);
      } else {
       await this.updateCommitAction(COMMIT_TO_CURRENT_BRANCH);
      }
      if (!this.canPushCodeStatus.isAllowed) {
       await  Api.commitMultiple(id,data)
      }
    await this.commitChanges();
          // console.log(789)
      }
      // console.log(265166)
        let file_path1=localStorage.getItem('file_path')
        // console.log(file_path1,"file_path1")
      // this.commitChanges()
       this.loadFileContent(file_path1)
    },
    
    loadFileContent(path) {
      // e=file_path1
      // console.log(path,"path")
      this.getFileData({ path, makeFileActive: false }).then(() =>
        this.getRawFileData({ path }),
      );
    },
    createFromTemplate (template) {
      // console.log(template,"template")
      
      const parent = getPathParent(this.entryName);
      const name = parent ? `${parent}/${template.name}` : template.name;
      console.log(name,"name")
      this.createTempEntry({
        name,
        type: this.modalType,
      });

      this.$refs.modal.toggle();
      // console.log(165165)
    },
    focusInput () {
      const name = this.entries[this.entryName]?.name;
      const inputValue = this.$refs.fieldName.value;

      this.$refs.fieldName.focus();

      if (name) {
        this.$refs.fieldName.setSelectionRange(inputValue.indexOf(name), inputValue.length);
      }
    },
    resetData () {
      console.log(123456789)
      this.entryName = '';
      this.path = '';
      this.modalType = modalTypes.blob;
      console.log(this.modalType,"this.modalType")
    },
    open (type = modalTypes.blob, path = '') {
      this.modalType = type;
      this.path = path;

      if (this.modalType === modalTypes.rename) {
        this.entryName = path;
      } else {
        this.entryName = path ? `${path}/` : '';
      }

      this.$refs.modal.show();

      // wait for modal to show first
      this.$nextTick(() => this.focusInput());
    },
    close () {
      this.$refs.modal.hide();
    },
  },
};

</script>

<template>
  <gl-modal
    ref="modal"
    modal-id="ide-new-entry"
    data-qa-selector="new_file_modal"
    data-testid="ide-new-entry"
    :title="modalTitle"
    :ok-title="buttonLabel"
    ok-variant="success"
    size="lg"
    @ok="submitForm"
    @hide="resetData"
  >
    <div class="form-group row">
      <label class="label-bold col-form-label col-sm-2"> {{ __('Name') }} </label>
      <div class="col-sm-10">
        <input
          ref="fieldName"
          v-model.trim="entryName"
          type="text"
          class="form-control"
          data-testid="file-name-field"
          data-qa-selector="file_name_field"
          :placeholder="placeholder"
        />
        <ul v-if="isCreatingNewFile" class="file-templates gl-mt-3 list-inline qa-template-list">
          <li v-for="(template, index) in templateTypes" :key="index" class="list-inline-item">
            <gl-button
              variant="dashed"
              category="secondary"
              class="p-1 pr-2 pl-2"
              @click="createFromTemplate(template)"
            >
              {{ template.name }}
            </gl-button>
          </li>
        </ul>
      </div>
    </div>
  </gl-modal>
</template>
